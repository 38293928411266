/*** IMPORTS FROM imports-loader ***/

var define = false;

const ACTION_TYPES = {
    NORMAL: 'N',
    POPUP: 'P',
    AJAX: 'A',
    ONCLICK: 'O',
    ICONE: 'I',
    NOVAJANELA: 'NJ',
}

export default ACTION_TYPES